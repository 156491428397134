import React, { useState, useEffect } from "react";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import { TextField, IconButton, Button, InputAdornment } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../services/Auth/Inteceptor";
import environment from "../../environments/environments";
import resetsenhamodel from "../../models/resetsenha.model";
import * as CryptoJS from "crypto-js";
import "./Reset.scss";

const Reset = () => {
  const navigate = useNavigate();

  const [formreset, setformreset] = useState(new resetsenhamodel());

  const [novaSenha, setNovaSenha] = useState("");
  const [confirmaSenha, setConfirmaSenha] = useState("");
  const [senhaValida, setSenhaValida] = useState(true);
  const [senhaConfirmaValida, setSenhaConfirmaValida] = useState(true);

  const [status, setStatus] = useState(0); //0 - campo digitavel  1- Token Valido 2- Token Invalido

  const { token } = useParams();
  const urlTrocarSenha = environment.urlTrocarSenha;

  const handleNovaSenhaChange = (e) => {
    setNovaSenha(e.target.value);
    const isSenhaValida =
      e.target.value.length >= 8 &&
      /[a-zA-Z]/.test(e.target.value) &&
      /[0-9]/.test(e.target.value);
    setSenhaValida(isSenhaValida);
  };

  useEffect(() => {
    if (novaSenha) {
      const hashpassword = CryptoJS.SHA256(novaSenha).toString();      
      setformreset({ ...formreset, novaSenha: hashpassword });
    }
  }, [novaSenha]);

  const handleEmailChange = (e) => {
    setformreset({ ...formreset, email: e.target.value });
  };

  const handleConfirmaSenhaChange = (e) => {
    setConfirmaSenha(e.target.value);
    setSenhaConfirmaValida(novaSenha === e.target.value);
    
  };

  const MudarSenha = (e) => {    
    e.preventDefault()    
    api.post(`${urlTrocarSenha}${token}`, formreset).then((response) => {
      if (response.data.info.dados.erro === "Token de validação expirado") {
        setStatus(2);
      } else {
        setStatus(1);
      }
    });
  };

  return (
    <div className="card-reset">
      <h2>Redefinição de Senha</h2>

      {status === 0 && (
        <form className="form">
          <div className="input-group">
            <TextField
              id="email"
              type="text"
              label="Confirme seu Email"
              value={formreset.email}
              onChange={handleEmailChange}
              variant="outlined"
              fullWidth
            />
          </div>
          <div className="input-group">
            <TextField
              id="novaSenha"
              type="password"
              label="Digite sua nova Senha"
              value={novaSenha}
              onChange={handleNovaSenhaChange}
              variant="outlined"
              fullWidth
              error={!senhaValida}
              helperText={
                !senhaValida
                  ? "A senha deve conter pelo menos 8 dígitos, letras e números."
                  : ""
              }
            />
          </div>

          <div className="input-group">
            <TextField
              id="confirmaSenha"
              type="password"
              label="Confirme sua senha"
              value={confirmaSenha}
              onChange={handleConfirmaSenhaChange}
              variant="outlined"
              fullWidth
              InputProps={{
                endAdornment: (
                  <>
                    <InputAdornment position="end">
                      <IconButton
                        disabled={!senhaConfirmaValida || formreset.email === ''}
                        type="submit"
                        className="submit-icon"
                        onClick={MudarSenha}
                      >
                        <ArrowCircleRightOutlinedIcon />
                      </IconButton>
                    </InputAdornment>
                  </>
                ),
              }}
              error={!senhaConfirmaValida}
              helperText={
                !senhaConfirmaValida ? "As senhas não coincidem." : ""
              }
            />
          </div>
        </form>
      )}
      {status === 1 && (
        <div>
          <h2>Senha Alterada.</h2>
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate("/login")}
          >
            {" "}
            Login{" "}
          </Button>
        </div>
      )}
      {status === 2 && (
        <div>
          <h2>Token Expirado!, Solicite novamente</h2>
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate("/login")}
          >
            {" "}
            Voltar{" "}
          </Button>
        </div>
      )}
    </div>
  );
};

export default Reset;
