import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Login from '../page/Login/Login';
import Home from '../page/Home/Home';
import Signup from '../page/Signup/Signup';
import TipodeAcesso from '../page/TipodeAcesso/TipodeAcesso';
import RecuperarSenha from '../page/RecuperarSenha/recuperarsenha';
import Reset from '../page/Reset/Reset';
import ConfirmEmail from '../page/ConfirmEmail/ConfirmEmail';
import PrivateRoute from './PrivateRoute';

const RoutesApp = () => {
  return (    
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/tipodeacesso" element={<TipodeAcesso />} />
      <Route path="/forgot-password" element={<RecuperarSenha />} />
      <Route path="/reset-senha/:token" element={<Reset />} />
      <Route path="/confirmar-email/:token" element={<ConfirmEmail />} />
      
      {/* Rotas protegidas */}
     <Route path="/home" element={<PrivateRoute element={<Home />} />} />
      
      <Route path="*" element={<Login />} /> {/* Página padrão */}
    </Routes>
  );
};

export default RoutesApp;
