import { GetTokenDBWEB, isAuthenticatedDBWEB } from "./Auth/Auth.Service";
import api from "./Auth/Inteceptor";
import environment from "../environments/environments";

const urlBuscaCnpjTanis = environment.urlBuscaCnpjTanis;
const urlBuscaCnpj = environment.urlBuscaCnpj;

export function getCookie(name) {
  const cookies = document.cookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
    let cookie = cookies[i].trim();
    if (cookie.startsWith(name + "=")) {
      return cookie.substring(name.length + 1, cookie.length);
    }
  }
  return null;
}

export const BuscaCNPJTanis = async (cnpj) => {
  if (!isAuthenticatedDBWEB()) {
    await GetTokenDBWEB();
  }
  try {
    const response = await api.getDBWEB(`${urlBuscaCnpjTanis}${cnpj}`);
    return response;
  } catch (error) {
    console.error("Erro ao buscar CNPJ Tanis:", error);
    throw error;
  }
};

export const BuscaCNPJ = async (cnpj) => {
  if (!isAuthenticatedDBWEB()) {
    await GetTokenDBWEB();
  }

  try {
    const response = await api.getDBWEB(`${urlBuscaCnpj}${cnpj}`);
    return response;
  } catch (error) {
    console.error("Erro ao buscar CNPJ:", error);
    throw error;
  }
};
