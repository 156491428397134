import { useEffect, useRef } from "react";
import { logout } from "./Auth/Auth.Service";
import { useNavigate } from "react-router-dom";

const AutoLogout = () => {
  const navigate = useNavigate();
  const timeoutRef = useRef(null);

  const resetTimeout = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      logout();
      navigate("/login");  
    }, 3600000); // 1 hora
  };

  useEffect(() => {
    const events = [
      "mousemove",
      "mousedown",
      "keypress",
      "scroll",
      "touchstart",
    ];

    events.forEach((event) => {
      window.addEventListener(event, resetTimeout);
    });

    resetTimeout();
    
    return () => {
      events.forEach((event) => {
        window.removeEventListener(event, resetTimeout);
      });
      clearTimeout(timeoutRef.current);
    };
  }, [navigate]);

  return null;
};

export default AutoLogout;
