import React, { useEffect, useState } from "react";
import { Button, TextField } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../services/Auth/Inteceptor";
import environment from "../../environments/environments";
import "./ConfirmEmail.scss";

const ConfirmEmail = () => {
  const [status, setStatus] = useState(0); //0 - Verificando  1- Token Valido 2- Token Invalido
  const [email, setEmail] = useState("");

  const urlConfirmaEmail = environment.urlConfirmaEmail;
  const urlReenviaEmail = environment.urlReenviaEmail;

  const navigate = useNavigate();
  const { token } = useParams();

  const handleEmailChange = (e) => {
    const emailValue = e.target.value;
    setEmail(emailValue);
  };

  const reenviaToken = () => {
    api.get(`${urlReenviaEmail}${email}`, "").then((response) => {      
      setStatus(3); 
    });
  };

  const ConfirmaEmail = () => {
    api.post(`${urlConfirmaEmail}${token}`, "")
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {       
        setStatus(1); 
      } else {        
        setStatus(2); 
      }
    })
    .catch((error) => {
      setStatus(2);      
    });}

  useEffect(() => {
    ConfirmaEmail();
  }, []);

  return (
    <div className="contenier-confirmmail">
      <div className="card">
        {status === 0 && (
          <div>
            <h2>Verificando Token ...</h2>
          </div>
        )}
        {status === 1 && (
          <div>
            <h2>E-Mail Confirmado</h2>
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate("/login")}
            >
              {" "}
              Login{" "}
            </Button>
          </div>
        )}
        {status === 2 && (
          <div>
            <h2>Token Expirado!</h2>
            <div className="input-container">
              <TextField
                id="email"
                label="Digite seu E-mail"
                variant="outlined"
                value={email}
                onChange={handleEmailChange}
                className="input-field"
              />
            </div>
            <Button variant="contained" color="primary" onClick={reenviaToken}>
              {" "}
              Solicitar Novo Token{" "}
            </Button>
          </div>
        )}
        {status === 3 && (
          <div>
            <h2>Solicitação Enviada</h2>
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate("/login")}
            >
              {" "}
              Login{" "}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ConfirmEmail;
