import React, { useState } from "react";
import {
  Container,
  TextField,
  MenuItem,
  Button,
  Grid,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import { BuscaCNPJ } from "../../services/Utils.Service";
import "./TipodeAcesso.scss";

const TipodeAcesso = () => {
  const [modo, settipodeAcesso] = useState({
    tipodeAcesso:"",
  });

  const [formtanis, setformtanis] = useState({
    cnpj: "",
    razaoSocial: "",
    nomeFantasia: "", 
    cnae: "",
    telefone: ""    
  });

  const [formope, setformope] = useState({
    nomeCompleto: "",
    cargo: "",
    departamento: "",
    gestorTelefone: "",
    gestorCelular: "",
    autorizadoraCNPJ: "",
  });

  const formatarCNPJ = (cnpj) => {
    const cnpjLimpo = cnpj.replace(/\D/g, "");
    return cnpjLimpo.length === 14 ? cnpjLimpo : null;
  };

  const buscarCNPJ = async (cnpj) => {
    const cnpjValido = formatarCNPJ(cnpj);
    if (!cnpjValido) {
      return;
    }

    try {
      const response = await BuscaCNPJ(cnpjValido);
      const dados = response.data.info.dados
      setformtanis((prevState) => ({
        ...prevState,
        razaoSocial: dados.nome,
        nomeFantasia: dados.fantasia,       
        cnae: dados.atividade_principal[0].text,
        telefone: dados.telefone,      
      }));
    } catch (error) {
      console.error("Erro ao buscar o CNPJ:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setformtanis((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    setformope((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    if (name === "cnpj") {
      buscarCNPJ(value);
    }
  };

  const handleTipodeAcessoChange = (e) => {
    settipodeAcesso((prevState) => ({
      ...prevState,
      tipodeAcesso: e.target.value,
    }));
  };

  const handleSubmit = () => {
    alert("Cadastro concluído com sucesso!");
  };

  return (
    <Container>
      <div className="acesso-container">
        <div className="card-type">
          <h2 className="title">Tipo de Acesso</h2>
          <FormControl fullWidth className="form-control" sx={{ mb: 2 }}>
            <InputLabel id="simple-select-label">Tipo de Acesso</InputLabel>
            <Select
              labelId="simple-select-label"
              id="simple-select"
              value={modo.tipodeAcesso}
              label="Tipo de Acesso"
              onChange={handleTipodeAcessoChange}
              className="MuiSelect-root"
            >
              <MenuItem value={1}>Cliente Tanis</MenuItem>
              <MenuItem value={2}>Operador</MenuItem>
            </Select>
          </FormControl>

          {modo.tipodeAcesso === 1 && (
            <div>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="cnpj"
                    label="CNPJ"
                    value={formtanis.cnpj}
                    onChange={handleChange}
                    fullWidth
                    className="input-field"
                    placeholder="00.000.000/0000-00"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="telefone"
                    label="Telefone"
                    value={formtanis.telefone}
                    fullWidth
                    className="input-field"
                  />
                </Grid>
                
                <Grid item xs={12} sm={12}>
                  <TextField
                    name="razaoSocial"
                    label="Razão Social"
                    value={formtanis.razaoSocial}
                    InputProps={{ readOnly: true }}
                    fullWidth
                    className="input-field"
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    name="nomeFantasia"
                    label="Nome Fantasia"
                    value={formtanis.nomeFantasia}
                    InputProps={{ readOnly: true }}
                    fullWidth
                    className="input-field"
                  />
                </Grid>                
                <Grid item xs={12} sm={12}>
                  <TextField
                    name="cnae"
                    label="CNAE"
                    value={formtanis.cnae}
                    InputProps={{ readOnly: true }}
                    fullWidth
                    className="input-field"
                  />
                </Grid>
                
              </Grid>
            </div>
          )}

          {modo.tipodeAcesso === 2 && (
            <div>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <TextField
                    name="nomeCompleto"
                    label="Nome Completo"
                    value={formope.nomeCompleto}
                    onChange={handleChange}
                    fullWidth
                    className="input-field"
                    required
                  />
                </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="cargo"
                    label="Cargo"
                    value={formope.cargo}
                    onChange={handleChange}
                    fullWidth
                    className="input-field"
                    required                   
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="departamento"
                    label="Departamento"
                    value={formope.departamento}
                    onChange={handleChange}
                    fullWidth
                    className="input-field"
                    required                    
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="gestor"
                    label=" Gestor"
                    value={formope.gestor}
                    onChange={handleChange}
                    fullWidth
                    className="input-field"
                    required                    
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="telefone"
                    label="Telefone"
                    value={formope.telefone}
                    onChange={handleChange}
                    fullWidth
                    className="input-field"
                    required                    
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="celular"
                    label="Celular"
                    value={formope.celular}
                    onChange={handleChange}
                    fullWidth
                    className="input-field"
                    required                    
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="autorizadoraCNPJ"
                    label="CNPJ da Organização"
                    value={formope.autorizadoraCNPJ}
                    onChange={handleChange}
                    fullWidth
                    className="input-field"
                    placeholder="12.345.678/0000-00"
                    inputProps={{
                      maxLength: 18,
                    }}
                  />
                </Grid>
            </div>
          )}

          <div className="actions">
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              sx={{ mt: 2, mr: 1 }}
            >
              Confirmar
            </Button>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default TipodeAcesso;
