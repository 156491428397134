import { createGlobalStyle } from "styled-components";
import bg_login from  "../assets/bg_login.png"

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=M+PLUS+1p:wght@100;300;400;500;700;800;900&display=swap');

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
     font-family: 'M PLUS 1p', system-ui, sans-serif; 

  }

  body {
    background-image: url(${bg_login});
    background-size: cover;
    min-height: 100vh;
    background-position: center;
 
    width: 100vw;
    height: 100vh;
  }
`;

export default GlobalStyle;
