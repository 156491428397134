import React, { useState, useEffect } from "react";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import { TextField, IconButton, InputAdornment } from "@mui/material";
import * as CryptoJS from "crypto-js";
import logo from "../../assets/logo.png";
import lg_tanishub from "../../assets/lg_tanishub.png";
import { useNavigate } from "react-router-dom";
import LoginModel from "../../models/login.model";
import "./Login.scss";
import { login, isAuthenticated } from "../../services/Auth/Auth.Service";
import {useNotification} from "../../components/notification"

const Login = () => {
  const [formlogin, setformlogin] = useState(new LoginModel());
  const [vpassword, setpassword] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(false);

  const navigate = useNavigate();
  const notification = useNotification();

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const handleEmailChange = (e) => {
    const emailValue = e.target.value;    
    setIsEmailValid(validateEmail(emailValue));
    setformlogin({ ...formlogin, login: emailValue});
  };

  const handlePasswordChange = (e) => {
    setpassword(e.target.value);    
  };

  useEffect(() => {
    if (vpassword) {
      const hashpassword = CryptoJS.SHA256(vpassword).toString();      
      setformlogin({ ...formlogin, password: hashpassword });
    }
  }, [vpassword]);

  const handleSubmit = async () => {      
   notification('Realizando Login', 'warning')
   const result = await login(formlogin);
   if (result.success) {      
    navigate("/home")
    notification('Login efetuado', 'success')
   } else{
    notification(result.error, 'error')   
   }
  };

  return (
    <>
      <div className="contenier-login">
        <div className="card2">
          <div>
            <div>
              <img src={lg_tanishub} alt="Logo da Empresa" style={{ width: "120px", height: "100px", marginRight: "10px" }} />
            </div>
            <h2>Iniciar Sessão</h2>
            <form>
              <div className="input-container">
                <TextField
                  id="login"
                  label="Digite seu E-mail"
                  variant="outlined"                  
                  value={formlogin.email}
                  onChange={handleEmailChange}
                  className="input-field"
                />
              </div>

              {isEmailValid && (
                <div className="input-container">
                  <TextField
                    id="password"
                    label="Digite sua Senha"
                    value={vpassword}
                    type="password"
                    onChange={handlePasswordChange}
                    className="input-field"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleSubmit}
                            onMouseDown={(event) => event.preventDefault()}
                          >
                            <ArrowCircleRightOutlinedIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              )}

              <div className="links">
                <span className="link" onClick={() => navigate("/signup")}>
                  Criar conta
                </span>
                <span
                  className="link"
                  onClick={() => navigate("/forgot-password")}
                >
                  Esqueci minha senha
                </span>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
