class resetsenhamodel {
    constructor(email, novaSenha) {
      this.email = email;
      this.novaSenha = novaSenha;     
    }
    toJson() {
      return {
        email: this.email,
        novaSenha: this.novaSenha,       
      };
    }
    static fromJson(json) {
      return new resetsenhamodel(
        json.email,
        json.novaSenha,       
      );
    }
  }
  
  export default resetsenhamodel
  