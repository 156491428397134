const urlDBWEB = "https://api-dbweb-tanis.fwc.cloud:8084/api/dbweb/v10";
const urlBase = "https://dev-login-api.tanis-hub.com.br/api/hublogin/v10";

export const environment = {
  urlLogin: urlBase + "/Usuarios/login",
  urlTokenDBWEB: urlDBWEB + "/auth/gettoken",
  urlBuscaCnpj: urlDBWEB + "/Cnpj/DadosCNPJ/ReceitaWS/",
  urlBuscaCnpjTanis: urlDBWEB + "/Empsis/info/",
  urlRegistroUser: urlBase + "/Usuarios/registrar",
  urlConfirmaEmail: urlBase + "/Usuarios/confirmar-email/",
  urlReenviaEmail: urlBase + "/Usuarios/reenvia-confirmacao-email/",
  urlResetSenha: urlBase + "/Usuarios/reset-senha/",
  urlTrocarSenha: urlBase + "/Usuarios/troca-senha/",
};

export default environment;
