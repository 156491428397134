class SingupModel {
  constructor(nome, cpf, dataNascimento, email, senha) {
    this.nome = nome;
    this.cpf = cpf;
    this.dataNascimento = dataNascimento;
    this.email = email;
    this.senha = senha;
  }
  toJson() {
    return {
      nome: this.nome,
      cpf: this.cpf,
      dataNascimento: this.dataNascimento,
      email: this.email,
      senha: this.senha,
    };
  }
  static fromJson(json) {
    return new SingupModel(
      json.nome,
      json.cpf,
      json.dataNascimento,
      json.email,
      json.senha
    );
  }
}

export default SingupModel
