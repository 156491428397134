import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { isAuthenticated } from '../services/Auth/Auth.Service';

const PrivateRoute = ({ element }) => {
  const navigate = useNavigate();

  useEffect(() => {    
    if (!isAuthenticated()) {
      navigate('/login');
    }
  }, [navigate]);

  return isAuthenticated() ? element : null;  
};

export default PrivateRoute;
