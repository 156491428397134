import axios from "axios";

export const GetTokenCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);

  if (parts.length === 2) return parts.pop().split(";").shift();
  return null;
};

const req = axios.create({
  timeout: 10000,
});

req.interceptors.request.use(
  (config) => {
    const token = GetTokenCookie("authToken");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const reqDBWEB = axios.create({
  timeout: 10000,
});

reqDBWEB.interceptors.request.use(
  (config) => {
    const token = GetTokenCookie("TokenDBWEB");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const api = {
  get: (url) => req.get(url),
  post: (url, data) => req.post(url, data),
  delete: (url) => req.delete(url),

  getDBWEB: (url) => reqDBWEB.get(url),
  postDBWEB: (url, data) => reqDBWEB.post(url, data),
  deleteDBWEB: (url) => reqDBWEB.delete(url),
};

export default api;
