import axios from "axios";
import environment from "../../environments/environments";
import * as CryptoJS from 'crypto-js';

const urlTokenDBWEB = environment.urlTokenDBWEB;
const urlLogin = environment.urlLogin;

const setCookie = (name, value, hours) => {
  const expires = new Date(Date.now() + hours * 60 * 60 * 1000).toUTCString();
  const domain = window.location.hostname;
  document.cookie = `${name}=${value}; expires=${expires}; path=/; domain=${domain}; Secure; SameSite=Strict`;  
};

export const isAuthenticated = () => {
  const cookieValue = document.cookie
    .split('; ')
    .find(row => row.startsWith(`${"authToken"}=`));
    return !!cookieValue;
};

export const isAuthenticatedDBWEB = () => {
  const cookieValue = document.cookie
    .split('; ')
    .find(row => row.startsWith(`${"TokenDBWEB"}=`));
  return cookieValue ? true : false;
};

export const login = async (body) => {
  try {
    const response = await axios.post(`${urlLogin}`, body);
    const token = response.data.info.dados.token; 
    const usuario = response.data.info.dados.usuario.nome; 
    setCookie("authToken", token, 12);            
    document.cookie = `username=${usuario}; path=/`;
    return { success: true}
  } catch (error) {        
    document.cookie = `authToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; Secure; SameSite=Strict;`;
    document.cookie = `TokenDBWEB=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; Secure; SameSite=Strict;`;    
    return { success: false, error: error.response.data.info.dados.descricao };
  }
};

export const logout = () => {
  document.cookie = `authToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; Secure; SameSite=Strict;`;
  document.cookie = `TokenDBWEB=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; Secure; SameSite=Strict;`;
  window.location.reload();
};

export const GetTokenDBWEB = async () => {
  try {   
    const hashpassword = CryptoJS.MD5("TANIS24").toString()

    const body = {
      cnpj: "01114682000126",
      sistema: "Mercantis",
      login: "Master",
      password: hashpassword,
    };
    const response = await axios.post(`${urlTokenDBWEB}`, body);    
    const token = response.data.info.dados.token;    
    setCookie("TokenDBWEB", token, 12);    
    return true
  } catch (error) {
    return false   
  }
};
