import React, { useState, useEffect } from "react";
import {
  AppBar,
  Box,
  IconButton,
  Toolbar,
  createTheme,
  ThemeProvider,
  CssBaseline,
  Badge,
  Button,
  Menu,
  MenuItem,
  FormControlLabel,
  FormGroup,
  Switch,
  Avatar,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import MenuIcon from "@mui/icons-material/Menu";
import HomeIcon from "@mui/icons-material/Home";
import AppsIcon from "@mui/icons-material/Apps";
import NotificationsIcon from "@mui/icons-material/Notifications";
import SettingsIcon from "@mui/icons-material/Settings";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MoreIcon from "@mui/icons-material/MoreVert";
import WbSunnyIcon from "@mui/icons-material/WbSunny";
import NightsStayIcon from "@mui/icons-material/NightsStay";
import bg_theme_light from "../../assets/bg_theme_light.png";
import bg_theme_dark from "../../assets/bg_theme_dark.png";
import lg_theme_light from "../../assets/lg_theme_light.svg";
import lg_theme_dark from "../../assets/lg_theme_dark.svg";
import logo from "../../assets/logo.png";
import { logout } from "../../services/Auth/Auth.Service";
import {
  Sidebar,
  Menu as ProMenu,
  MenuItem as ProMenuItem,
} from "react-pro-sidebar";
import { getCookie } from "../../services/Utils.Service";
import "./Home.scss";

const lightTheme = createTheme({
  palette: {
    mode: "light",
    primary: { main: "#000a0a" },
    secondary: { main: "#4d5bff" },
    text: { primary: "#000", secondary: "#555" },
    background: { default: "#ffffff" },
  },
});

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: { main: "#ffffff" },
    secondary: { main: "#001540" },
    text: { primary: "#ffffff", secondary: "#e0e0e0" },
    background: { default: "#121212" },
  },
});

const CardApp = ({ isDarkMode }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const toggleDescription = () => setIsExpanded(!isExpanded);

  return (
    <div className="card-app">
      <a
        href="https://link-do-seu-aplicativo.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={logo} alt="Logo" className="logo" />
        <span className="app-name">Mercantis</span>
      </a>
      <div className="descricao">
        <p>
          <strong>Descrição:</strong>
          <br />
          {isExpanded
            ? "O Software ERP Mercantis facilita o fluxo de informações de uma organização com objetivo de otimizar a tomada de decisões dentro da empresa. Portanto, o nosso sistema oferece as melhores soluções para uma gestão de alto desempenho focada nos Distribuidores Hospitalares. Tecnologia e inovação fazem parte do nosso DNA."
            : "O Software ERP Mercantis facilita o fluxo de informações de uma organização... "}
          <span
            onClick={toggleDescription}
            style={{ color: "blue", cursor: "pointer" }}
          >
            {isExpanded ? "Ler menos" : "Ler mais"}
          </span>
        </p>
      </div>
    </div>
  );
};

const Home = () => {
  const MaterialUISwitch = styled(Switch)(({ theme }) => ({
    width: 62,
    height: 34,
    padding: 7,
    "& .MuiSwitch-switchBase": {
      margin: 7,
      padding: 0,
      transform: "translateX(6px)",
      "&.Mui-checked": {
        color: isDarkMode ? "#fff" : "#0f0f0f",
        transform: "translateX(22px)",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor: isDarkMode ? "#000a1f" : "#b3b1b1",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      backgroundColor: isDarkMode ? "#000a1f" : "#FEF7FF",
      width: 30,
      height: 30,
    },
    "& .MuiSwitch-track": {
      opacity: 1,
      backgroundColor: isDarkMode ? "#000a1f" : "#cccaca",
      borderRadius: 20 / 2,
    },
  }));

  const [isOpen, setIsOpen] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [menuButton, setmenuButton] = useState(null);
  const [usuario, setusuario] = useState("");

  const toggleTheme = () => setIsDarkMode(!isDarkMode);
  const toggleside = () => setIsOpen(!isOpen);
  const handleClick = (event) => setmenuButton(event.currentTarget);
  const handleClose = () => setmenuButton(null);

  const logo = isDarkMode ? lg_theme_dark : lg_theme_light;

  const currentHour = () => {
    const hours = new Date().getHours();
    if (hours >= 6 && hours < 12) return "Bom dia";
    if (hours >= 12 && hours < 18) return "Boa tarde";
    return "Boa noite";
  };
  const sair = () => {
    logout();
  };

  useEffect(() => {
    setusuario(getCookie("username"));
  }, []);

  return (
    <ThemeProvider theme={isDarkMode ? darkTheme : lightTheme}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          width: "100%",
          backgroundColor: isDarkMode ? "#000A1F" : "#f5f5f5",
        }}
      >
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="primary"
            aria-label="open drawer"
            onClick={toggleside}
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <h1 style={{ color: "primary" }}>
            <img
              src={logo}
              alt="logo"
              style={{ width: "150px", height: "30px", marginRight: "10px" }}
            />
          </h1>
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            <FormGroup>
              <FormControlLabel
                control={
                  <MaterialUISwitch
                    checked={isDarkMode}
                    onChange={toggleTheme}
                    icon={<WbSunnyIcon sx={{ fontSize: 22, color:"#001540"}} />}
                    checkedIcon={<NightsStayIcon sx={{ fontSize: 22 }} />}
                  />
                }
                label=""
              />
            </FormGroup>
            <IconButton
              size="large"
              aria-label="show 13 new notifications"
              color="primary"
            >
              <Badge badgeContent={1} color="error">
                <NotificationsIcon />
              </Badge>
            </IconButton>
            <Avatar                           
              color="primary"
              onClick={handleClick}
              style={{ marginLeft: "10px" }}
              sx={{ bgcolor: isDarkMode ? "#f3f2f5" : "#001540"}}
            >
              {" "}
              {usuario
                .split(" ")
                .map((word) => word[0])
                .join("")}{" "}
              {/* Pega as iniciais */}
            </Avatar>
            <Menu
              anchorEl={menuButton}
              open={Boolean(menuButton)}
              onClose={handleClose}
              PaperProps={{
                style: {
                  maxHeight: 48 * 4.5,
                  width: "20ch",
                },
              }}
            >
              <MenuItem onClick={handleClose}>Configurações</MenuItem>
              <MenuItem onClick={handleClose}>Trocar de Empresa</MenuItem>
              <MenuItem onClick={sair}>Logout</MenuItem>
            </Menu>
          </Box>
          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <IconButton size="large" aria-label="show more" color="primary">
              <MoreIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      <Sidebar
        collapsed={!isOpen}
        collapsedWidth="70px"
        backgroundColor={isDarkMode ? "#001540" : "#f3f2f5"}
        style={{
          height: "calc(100vh - 64px)",
          position: "fixed",
          left: 0,
          top: 64,
          overflow: "auto",
          zIndex: (theme) => theme.zIndex.drawer,
        }}
      >
        <ProMenu
          menuItemStyles={{
            button: ({ level }) => {
              if (level === 0)
                return {
                  color: isDarkMode ? "#ffffff" : "#01050d",
                  backgroundColor: isDarkMode ? "#001540" : "#f3f2f5",
                  "&:hover": {
                    color: isDarkMode ? "#000000" : "#ffffff",
                    backgroundColor: isDarkMode ? "#ffffff" : "#001540",
                  },
                };
            },
          }}
        >
          <ProMenuItem icon={<HomeIcon />}>Inicio</ProMenuItem>
          <ProMenuItem icon={<AppsIcon />}>Aplicativos</ProMenuItem>
          <ProMenuItem icon={<SettingsIcon />}>Configurações</ProMenuItem>
        </ProMenu>
      </Sidebar>

      <main
        style={{
          padding: "20px",
          marginLeft: isOpen ? 250 : 70,
          marginTop: 64,
          backgroundImage: `url(${
            isDarkMode ? bg_theme_dark : bg_theme_light
          })`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          width: "100vw",
          height: "100vh",
          minHeight: "calc(100vh - 64px)",
          backgroundPosition: "center",
        }}
      >
        <div style={{ borderBottom: "1px solid rgba(255, 255, 255, 0.1)" }}>
          <strong>
            {currentHour()} {usuario}, tenha um bom serviço hoje
          </strong>
        </div>

        <div className="div-app">
          <strong>Meus Aplicativos</strong>
        </div>
        <div className="div-list">
          <Button className="box-button" style={{ marginRight: "20px" }}>
            <img src={logo} alt="Imagem" className="box-image" />
            <span className="box-text">Galago</span>
          </Button>
          <Button className="box-button" style={{ marginRight: "20px" }}>
            <img src={logo} alt="Imagem" className="box-image" />
            <span className="box-text">Vincis</span>
          </Button>
          <Button className="box-button" style={{ marginRight: "20px" }}>
            <img src={logo} alt="Imagem" className="box-image" />
            <span className="box-text">Kotamos</span>
          </Button>
        </div>

        <div
          style={{
            borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
            marginTop: "10px",
          }}
        >
          <strong>Contrate mais aplicativos</strong>
        </div>

        {/* Utilize o CardApp aqui */}
        <CardApp isDarkMode={isDarkMode} />
      </main>
    </ThemeProvider>
  );
};

export default Home;
