class LoginModel {
    constructor(login, password) {
      this.login = login;
      this.password = password;
      this.cnpj = "";
      this.sistema = "";
    }
    toJson() {
      return {
        login: this.login,
        password: this.password,
        cnpj: this.cnpj,
        sistema: this.sistema,
      };
    }
    static fromJson(json) {
      return new LoginModel(
        json.login,
        json.password,
        json.cnpj,
        json.sistema
      );
    }
  }
  
  export default LoginModel
  