import React, { useState } from "react";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import { TextField, IconButton, Button, InputAdornment } from "@mui/material";
import { useNavigate } from "react-router-dom";
import api from "../../services/Auth/Inteceptor";
import environment from "../../environments/environments";

import "./recuperarsenha.scss";

const RecuperarSenha = () => {
  const navigate = useNavigate();
  const [status, setStatus] = useState(0); //0 - campo digitavel  1- Enviado 2- Erro

  const urlResetSenha = environment.urlResetSenha;

  const ConfirmaPage = () => {
    api.get(`${urlResetSenha}${email}`).then((response) => {
      if (response.data.info.dados.erro === "Token de validação expirado") {
        setStatus(2);
      } else {
        setStatus(1);
      }
    });
  };

  const [email, setEmail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(false);

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const handleEmailChange = (e) => {
    const emailValue = e.target.value;
    setEmail(emailValue);
    setIsEmailValid(validateEmail(emailValue));
  };

  return (
    <>
      <div className="contenier-rec">
        <div className="cardrec">
          {status === 0 && (
            <div>
              <h2>Redefinir sua senha</h2>
              <div className="form">
                <label>
                  {" "}
                  Para continuar, insira o endereço de e-mail que você usa com
                  sua conta.
                </label>
                <form>
                  <TextField
                    id="email"
                    label="Digite seu E-mail"
                    variant="outlined"
                    value={email}
                    onChange={handleEmailChange}
                    className="input-field"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            disabled={isEmailValid === false}
                            onClick={ConfirmaPage}                            
                          >
                            <ArrowCircleRightOutlinedIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </form>
              </div>
            </div>
          )}
          {status === 1 && (
            <div>
              <h2>Verifique seu e-mail para redefinir sua senha</h2>
              <Button
                variant="contained"
                color="primary"
                onClick={() => navigate("/login")}
              >
                {" "}
                Voltar{" "}
              </Button>
            </div>
          )}
          {status === 2 && (
            <div>
              <h2>Erro tente novamente mais tarde</h2>
              <Button
                variant="contained"
                color="primary"
                onClick={() => navigate("/login")}
              >
                {" "}
                Voltar{" "}
              </Button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default RecuperarSenha;
